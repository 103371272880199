<template>
  <div>
    <div>
        <b-card no-body>
            <b-card-body class=" pl-1 ">
                <b-tabs lazy>
                    <b-tab title="Monthly view">
                        <div class="alert-body"><Gantt /></div>
                    </b-tab>
                    <b-tab title="Annual view">
                        <div class="alert-body"><Graph ref="graph" /></div>
                    </b-tab>
                </b-tabs>
                
            </b-card-body>
        </b-card>
    </div>
    <div>
        <b-card no-body>
            <b-card-body class=" pl-1 ">
                <b-tabs>
                    <b-tab lazy>
                     <template #title>
                      <span class="" ><strong>Pending requests</strong>
                      <span v-if="pendingRequestCount>0" class="ml-1 translate-middle badge rounded-circle bg-danger"
                          style="font-size: 0.8em;">{{pendingRequestCount}}</span>
                      </span>
                     </template>
                        <div class="alert-body"> <PendingRequests
                                                    @refreshData="getSummary()" />
                        </div>
                    </b-tab>
                    <b-tab lazy>
                     <template #title>
                      <span class="" ><strong>Today's leaves ({{todaysLeaveCount}})</strong>
                      </span>
                     </template>
                        <div class="alert-body"> <TodaysLeaves />
                        </div>
                    </b-tab>
                    <b-tab lazy>
                     <template #title>
                      <span class="" ><strong>Scheduled leaves ({{scheduledLeaveCount}})</strong>
                      </span>
                     </template>
                        <div class="alert-body"> <ScheduledLeaves
                                                    @refreshData="getSummary()" />
                        </div>
                    </b-tab>
                    <b-tab lazy>
                     <template #title>
                      <span class="" ><strong>Locked dates</strong>
                      </span>
                     </template>
                        <div class="alert-body"> <PracticeLeaves ref="practiceLeaves"/>
                        </div>
                    </b-tab>
                </b-tabs>
            </b-card-body>
        </b-card>
    </div>
    
  </div>
</template>

<script>
import AdminLeaveAPI from '@/apis/modules/leave_managment_admin'
import PendingRequests from './dashboard-views/pendingRequests.vue'
import ScheduledLeaves from './dashboard-views/scheduledLeaves.vue'
import TodaysLeaves from './dashboard-views/todaysLeaves.vue'
import PracticeLeaves from './dashboard-views/practiceLeaves.vue'
import Graph from './dashboard-views/leaveGraph.vue'
import Gantt from './dashboard-views/scheduler/index.vue'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import MomentMixin from '@/mixins/MomentMixin'
import {BAvatar, BBadge, BCard, BCardBody, BCardHeader, BCol, BImg, BMedia, BRow, BTabs , BTab,
    BButton,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButtonGroup,
    BOverlay} from 'bootstrap-vue'

export default {
  components: {
    Graph,
    Gantt,
    PendingRequests,
    ScheduledLeaves,
    TodaysLeaves,
    PracticeLeaves,
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BBadge,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButtonGroup,
    BOverlay,
    BButton,
    BTabs, 
    BTab
  },
    mixins: [ErrorMessages, SuccessMessage, MomentMixin],
    data() {
    return {
            pendingRequestCount: 0,
            todaysLeaveCount: 0,
            scheduledLeaveCount: 0,
        }
    },
    methods:{
        async getSummary(){
            try{
                const Response = await AdminLeaveAPI.summary()
                this.pendingRequestCount = Response.data.data.pending_request_count
                this.todaysLeaveCount = Response.data.data.todays_leave_count
                this.scheduledLeaveCount = Response.data.data.scheduled_leave_count
            }catch(error){
                this.convertAndNotifyError(error)
            }
        },
       refreshGraphData(){
        if(this.$refs['graph']){
            this.$refs['graph'].getGraphDetails()  
        }
        if(this.$refs['practiceLeaves']){
            this.$refs['practiceLeaves'].getPracticeLeaves() 
        }
        if(this.$refs['graph'] && this.$refs['practiceLeaves'] ){
            this.$refs['graph'].getGraphDetails()  
            this.$refs['practiceLeaves'].getPracticeLeaves() 

        }
   
        },


        
    },
    mounted(){
        this.getSummary()
    }

}
</script>
