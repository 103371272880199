<template>
  <b-sidebar
      width="800px"
      id="add-leave-sidebar"
      :visible="isAddLeaveSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-add-leave-sidebar-active', val)"

  >
    <template #default="{ hide }">

      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0">
          Add Leave
        </h4>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="cancel(); hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          ref="refFormObserver"
      >
      <b-overlay
        :show="formLoading"
        rounded="sm"
            >
        <!-- Form -->
        <div
            class="p-2"
        >
          <div class="alert d-flex font-weight-bold">
            <label class=" font-small-4 ">
              Use this form to create leave for practice staff. If you want to add leave to yourself, it won’t require approval.</label>
          </div>
          <b-row class="mb-1">
            <b-col cols="12" >
            <label>Add Leave For</label>
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="Staff"
                  :rules="admin_leave ? '' : 'required'"
              >
                <v-select
                    label="name"
                    placeholder="Select Staff"
                    v-model="StaffMember"
                    :reduce="name => name.id"
                    :options="options"
                    :disabled="disableStaff"
                    multiple
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              </b-form-group>
                <b-form-checkbox
                  v-model="admin_leave"
                  :value="true"
                >
                Add leave for myself
              </b-form-checkbox>
            </b-col>
          </b-row>
            <b-row class="mb-1">
                <b-col md="12" class="">
                  <h5 class="cursor-pointer">
                    Leave Type</h5>
                    <!-- <label>Use day off if the staff member is taking one or more full working day ({{practiceWorkingHours}} hours). Otherwise use hours off.</label> -->
                        <label> Leave is based on working hours. Please add the working hours under the profile section before adding leave. </label>
                </b-col>
            </b-row>
          <b-row >
            <b-col cols="12" md="4">
              <label>Start Date</label>
              <validation-provider
                  #default="{ errors }"
                  name="Start Date"
                  rules="required"
              >
              <b-form-group>
                <b-input-group class="input-group-merge">
                  <b-form-datepicker
                        id="start_date"
                        :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }"
                        v-model="startDate"
                        placeholder="Select"
                        start-weekday="1"
                        class="form-control"
                        locale='en-UK'
                        trim/>
                </b-input-group>
              </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="4">
              <label>End Date</label>
              <validation-provider
                  #default="{ errors }"
                  name="End Date"
                  rules="required"
              >
              <b-form-group>
                <b-input-group class="input-group-merge">
                   <b-form-datepicker
                        id="end_date"
                        :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }"
                        v-model="endDate"
                        :disabled="disableEndDate"
                        start-weekday="1"
                        :min="minDate"
                        class="form-control"
                        placeholder="Select"
                        locale='en-UK'
                        trim/>
                </b-input-group>
              </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-form
                ref="form"
                :style="{height: trHeight}"
                class="repeater-form"
                @submit.prevent="repeateAgain"
              >
          <b-row v-for="(item, index) in leaveItems"
                      :id="item.id"
                      :key="item.id"
                      ref="row">

            <b-col cols="12" md="3">
              <label>Date</label>
              <validation-provider
                  ref="date"
                  #default="{ errors }"
                  :bails="false"
                  :name="'Date'+index"
                  rules="required"
              >
              <b-form-group>
                <b-input-group class="input-group-merge">
                   <b-form-datepicker
                        :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }"
                        v-model="leaveItems[index].date"
                        placeholder="Select"
                        start-weekday="1"
                        :min="minDate"
                        :max="maxDate"
                        class="form-control"
                        locale='en-UK'
                        trim/>
                </b-input-group>
              </b-form-group>
               <div v-if="isEndDate">
                <small  class="text-danger" v-if="errors[0]">The Date field is required</small>
               </div>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="9">
              <b-row>
                <b-col cols="12" md="3">
              <label>Start Time</label>
              <validation-provider
                  ref="startTime"
                  #default="{ errors }"
                  :bails="false"
                  :name="'Start Time'+index"
                  rules="required"
              >
              <b-form-group>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ClockIcon"/>
                  </b-input-group-prepend>
                  <flat-pickr
                      v-model="leaveItems[index].startTime"
                      :config="{ enableTime: true, noCalendar: true,  dateFormat: 'H:i', }"
                      class="form-control"
                      placeholder="Select"
                  />
                </b-input-group>
              </b-form-group>
              <div v-if="isEndDate">
                <small  class="text-danger" v-if="errors[0]">The Start Time field is required</small>
              </div>
              </validation-provider>
            </b-col>
                <b-col cols="12" md="3">
              <label>End Time</label>
              <validation-provider
                  ref="endTime"
                  #default="{ errors }"
                  :bails="false"
                  :name="'End Time'+index"
                  rules="required"
              >
              <b-form-group>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ClockIcon"/>
                  </b-input-group-prepend>
                  <flat-pickr
                      v-model="leaveItems[index].endTime"
                      :config="{ enableTime: true, noCalendar: true,  dateFormat: 'H:i', }"
                      class="form-control"
                      placeholder="Select"
                  />
                </b-input-group>
              </b-form-group>
              <div v-if="isEndDate">
                <small  class="text-danger" v-if="errors[0]">The End Time field is required</small>
              </div>
              </validation-provider>
            </b-col>
                 <b-col class="" style="margin-top:32px" cols="12" md="4">
                <span class="set-mb">
                    <p><span style="color:#1B9AAA" v-if="hoursCalculate(leaveItems[index])>0">{{hoursCalculate(leaveItems[index])}} </span>
                      <span  style="color:#1B9AAA" v-if="hoursCalculate(leaveItems[index])>0"> hrs</span>
                      <span
                        v-if="leaveItems[index].break_minutes>0">{{
                        ` (${leaveItems[index].break_minutes} min break)`
                      }}</span>
                    </p>
                </span>
             </b-col>
              <b-col class="" style="margin-top:30px" cols="12" md="2">   
              <b-button v-if="leaveItems.length == index + 1"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="btn-icon rounded-circle"
                  size="20"
                  style="padding:5px"
                  variant="outline-white"
                  @click="repeateAgain"
              >
                <feather-icon icon="PlusCircleIcon" size="17"/>
              </b-button>
              <b-button
                  v-if="leaveItems.length > 1"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="btn-icon rounded-circle"
                  size="20"
                  style="padding:5px"
                  variant="outline-white"
                  @click="removeItem(index)"
              >
                <feather-icon icon="MinusCircleIcon" size="17"/>
              </b-button>
            </b-col>
              </b-row>
            </b-col>
          </b-row>
          </b-form>
          <b-form>

          </b-form>
          <b-row>
            <b-col class="mt-1" cols="12">
              <label>Reason</label>
                <validation-provider
                    #default="{ errors }"
                    name="Reason"
                    rules="required"
                >
                  <b-form-group>
                    <v-select
                      v-model="leaveReason"
                      :options="leaveReasonOptions"
                      :reduce="leaveReason => leaveReason.id"
                      label="name"
                      placeholder="Select"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-col>
          </b-row>
          <b-row v-if="showSicknesInput">
            <b-col class="mt-1" cols="12">
              <label>Sickness Type</label>
                <validation-provider
                    #default="{ errors }"
                    name="Reason"
                    rules="required"
                >
                  <b-form-group>
                    <v-select
                      v-model="sicknessType"
                      :options="sicknessTypeOptions"
                      :reduce="sicknessType => sicknessType.id"
                      label="name"
                      placeholder="Select"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-col>
          </b-row>
          <div v-if=" (StaffMember.length < 2 && leaveReason === 5) || ( StaffMember.length < 2 && leaveReason === 7 && isIncludeBankHolidays === true)">
          <div v-if="StaffMember.length >= 1 && StaffMember[0] !== 0" :hidden="saveDisable()" >
            <b-row class="d-flex"  v-if="trackLeaveOnDays">
              <div>
                <b-col class="mt-1">
                    <h4>
                      <span v-if="parseFloat(totalRequestedTime / practiceWorkingHours) >0" style="color:#1B9AAA">{{parseFloat(totalRequestedTime / practiceWorkingHours).toFixed(1)}}</span>
                      <span v-else style="color:#1B9AAA">0.0</span>
                       days of annual leave</h4>
                </b-col>
              </div>
              <div>
                <b-col class="mt-1">
                    <h4><span v-bind:class="classBind ? 'minus-value' : 'plus-value'" >{{parseFloat((allocatedLeaveDays - usedLeaveDays)- (totalRequestedTime / practiceWorkingHours)).toFixed(1)}}</span> days remaining</h4>
                </b-col>
              </div>
            </b-row>
            <div v-else>
              <b-row>
              <div>
                <b-col  class="mt-1">
                    <h4>
                    <span v-if="parseFloat(totalRequestedTime) > 0"
                    style="color:#1B9AAA">{{ parseFloat(totalRequestedTime).toFixed(1) }}</span>
                    <span v-else
                      style="color:#1B9AAA"> 0.0
                    </span> hours of annual leave</h4>
                  </b-col>
              </div>
              <div>
                <b-col class="mt-1">
                    <h4><span v-bind:class="classBind ? 'minus-value' : 'plus-value'" >{{parseFloat((allocatedLeaveHours - usedLeaveHours) - totalRequestedTime).toFixed(1)}}</span>
                     <!-- {{classBind}} hours remaining -->
                     hours remaining
                     </h4>
                </b-col>
              </div>
            </b-row>
            </div>
          </div>
          </div>


          <!-- Form Actions -->
          <div class="d-flex  mt-2">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                :disabled="saveDisable() || parseFloat(totalRequestedTime) < 0"
                @click="addLeave()"
            >
              Save 
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                class="text-primary ml-1 shadow"
                type="button"
                variant="outline-white"
                @click="cancel() ; hide"
            >
              Cancel
            </b-button>
          </div>

        </div>
      </b-overlay>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  VBTooltip,
  BRow,
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BFormTextarea,
  BSidebar,
  BInputGroupPrepend,
  BCol,
  BBadge,
  BOverlay,
  BTable,
  BFormRadio,
  BFormRadioGroup
} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {alphaNum, required} from '@validations'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import moment from 'moment'
import leave from '@/apis/modules/leave'
import AdminLeaveAPI from '@/apis/modules/leave_managment_admin'
import Filter from '@/mixins/FilterMixin'
import MomentMixin from '@/mixins/MomentMixin'
import DateDiff from 'date-diff'
import PracticeAPI from '@/apis/modules/practice'

export default {
  mixins: [MomentMixin, Filter],

  components: {
    flatPickr,
    vSelect,
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BFormTextarea,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    BRow,
    BCol,
    BInputGroupPrepend,
    BTable,
    BOverlay,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    VBTooltip,
    BFormRadioGroup

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },

  model: {
    prop: 'isAddLeaveSidebarActive',
    event: 'update:is-add-leave-sidebar-active'
  },
  props: {
    isAddLeaveSidebarActive: {
      type: Boolean
    },
  },
  data () {
    const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const minDate = new Date(today)
      minDate.setMonth(minDate.getMonth() )
      minDate.setDate(minDate.getDate())
      const maxDate = new Date(today)
    maxDate.setMonth(maxDate.getMonth())
    maxDate.setDate(maxDate.getDate())
    return {
      isIncludeBankHolidays: '',
      isEndDate: false,
      min: minDate,
      max: maxDate,
      minDate: '',
      maxDate: '',
      currentUserId:'',
      addLeaveForAllStaff:false,
      leaveReasonOptions:[],
      sicknessTypeOptions:[],
      showSicknesInput: false,
      trHeight: null,
      required,
      date: '',
      startTime: '',
      endTime: '',
      alphaNum,
      tableLoading: false,
      items: [],
      nextTodoId: 1,
      selectedLeaveType:'timeOff',
      StaffMember:[],
      admin_leave:false,
      Reason:'',
      formLoading:false,
      leaveReason:'',
      sicknessType:'',
      startDate:'',
      endDate:'',
      practiceWorkingHours:0,
      defaultItem:{
          id: 'new_' + 0,
          startTime: '',
          endTime: '',
          date: ''
      },
      leaveItems:[{
          id: 1,
          date:'',
          startTime:'',
          endTime:'',
          break_minutes: 0
        }],
      options:[],
      disableStaff:false,
      disableEndDate: true,
      disableStartDate: true,
      minDate:'',
      isActive: true,
      userDetails:'',
      allocatedLeaveDays: 0,
      usedLeaveDays: 0,
      remainingLeaveDays: 0,
      trackLeaveOnDays: '',
      allocatedLeaveHours: 0,
      usedLeaveHours: 0,
      totalRequestedTime:0,
      classBind:false,
      usedLeaves:0
    }
  },

  watch: {
    async StaffMember(val){
      if(val.length > 1){
        val.forEach(e => {
        if(e == 0){
          this.StaffMember = [0]
        }
      });
      }else{
        if(!val[0] == 0){
          await this.getUserSummaryDetails(val[0]);
        }
      }
      if( 1 > val.length){
        this.disableStartDate = true
      }else{
        this.disableStartDate = false
      }
      this.startDate = ''
      this.endDate = ''
    },
    async admin_leave(){
      if(this.admin_leave){
        this.disableStaff = true
        await this.addLeaveMySelft()
      }else{
        this.disableStaff = false
      }
    },
    'startDate': function(value){
      this.minDate = new Date(value)
      if(value) this.disableEndDate = false
      this.endDate = ''
    },
    'endDate': function(value){
      this.maxDate = new Date(value)
      if(this.StaffMember.length == 1){
        if(!this.StaffMember[0] == 0){
          this.getWorkingHours(this.StaffMember, this.startDate, this.endDate)
        }
      }
    },
    async leaveReason(data){
      await this.getSicknessTypes(data)
    },
    isAddLeaveSidebarActive(val){
      if(val){
        this.getStaff()
        this.getLeaveReasons()
        this.getManageLeaveDetails()
      }
    }
  },
  methods: {
    saveDisable() {
      if((this.StaffMember || this.admin_leave === 'true') && this.leaveItems[0].date && this.leaveItems[0].startTime && this.leaveItems[0].endTime && this.leaveReason && this.leaveItems[0].startTime !== this.leaveItems[0].endTime ){
        return !this.isActive
      }else{
        return this.isActive === true
      }
    },

    cancel(){
      this.leaveItems = [{
        id: 1,
        date: '',
        startTime: '',
        endTime: ''
      }],
      this.StaffMember = '',
      this.admin_leave = '',
      this.startDate = '',
      this.endDate = '',
      this.Reason = '',
      this.leaveReason=''
      this.isAddLeaveSidebarActive = false
      this.isEndDate = false;
      this.$refs.refFormObserver.reset();
    },

    toggle () {
      this.isAddLeaveSidebarActive = false
    },
    repeateAgain () {
      this.leaveItems.push({
        id: this.nextTodoId += 1,
      })
      this.isEndDate = false;
    },
    removeItem(index) {
      this.leaveItems.splice(index, 1)
      this.isEndDate = false;
    },

    async getWorkingHours(id, start, end){
      try{
        this.formLoading = true
        const Response = await AdminLeaveAPI.getUserWorkingHours(id)

        let mapper = Response.data.data.map((x) => ({
          start: x.times[0].start,
          end: x.times[0].end,
          break_minutes: x.times[0].break_minutes ? x.times[0].break_minutes : 0,
          day_number: x.day_number
        }))

        let dataMapper = [];
        let dataSetter = [];

        for(let i of mapper){
          let start =  new Date(i.start)
          let startHours = start.getHours()
          let startMinutes = start.getMinutes()
          let startSeconds = start.getSeconds()
          let startTime =  startHours + ":" + startMinutes + ":" + startSeconds;
          let end =  new Date(i.end)
          let endHours = end.getHours()
          let endMinute = end.getMinutes()
          let endSeconds = end.getSeconds()
          let endTime = endHours + ":" + endMinute + ":" + endSeconds;
          let day_num =  i.day_number
          let break_minutes = i.break_minutes
          dataMapper.push({day_num: day_num, start: startTime, end: endTime, break_minutes})
        }

        var loopstart = new Date(start)
        var loopEnd = new Date(end)

        while(loopstart <= loopEnd){

          let dayNumber = loopstart.getDay()
          let date = this.momentFormat(loopstart, 'YYYY-MM-DD')
          for(let j of dataMapper){
            if(j.day_num == dayNumber){
              let start = moment(date + ` ` + j.start).format('YYYY-MM-DD HH:mm:ss')
              let end = moment(date + ` ` + j.end).format('YYYY-MM-DD HH:mm:ss')
              let break_minutes = j.break_minutes
              dataSetter.push({start: start, end: end, break_minutes})
            }
          }
          var newDate = loopstart.setDate(loopstart.getDate() + 1);
          loopstart = new Date(newDate);
        }

        let newArray = dataSetter.map((x) => ({
          startTime: this.momentFormat(x.start, 'HH:mm'),
          endTime: this.momentFormat(x.end, 'HH:mm'),
          date: this.momentFormat(x.start, 'YYYY-MM-DD'),
          break_minutes: x.break_minutes
        }))
        if(newArray.length > 0){
          this.leaveItems = newArray
        }else{
          this.leaveItems = [{
            id: 1,
            date:'',
            startTime:'',
            endTime:'',
            break_minutes: 0
          }]
        }
        this.formLoading = false
      }catch(error){
        this.formLoading = false
        this.convertAndNotifyError(error)
      }
    },
    async addLeave() {
      if(parseFloat((this.allocatedLeaveHours - this.usedLeaveHours) - this.totalRequestedTime) < 0){
        this.showErrorMessage('Please contact the Admin')
      }
      else{
      if (await this.$refs.refFormObserver.validate()) {
        try {
          let response = [];
          let user_ids = [];
          if(this.StaffMember[0]==0){
            this.options.forEach(e =>{
              if(!e.id == 0){
                user_ids.push(e.id);
              }
            });
          }else{
            user_ids = this.StaffMember
          }
          let payload = {}
          let leaves = []
          leaves = this.leaveItems.map((x) => ({
                start: moment(x.date + ` ` + x.startTime).utc().format('YYYY-MM-DD HH:mm:ss'),
                end: moment(x.date + ` ` + x.endTime).utc().format('YYYY-MM-DD HH:mm:ss'),
                break_minutes: x.break_minutes ? x.break_minutes: 0 ,
              })
          )

          payload.leaveItems = leaves
          payload.start = this.leaveItems[0].date + ` ` + this.leaveItems[0].startTime
          payload.user_id = user_ids
          payload.leave_reason = this.leaveReason
          payload.sickness_type = this.sicknessType
          payload.is_day = false
          response = await leave.requestAdminAndStaffLeaves(payload)
          if(response.data.success) {
            this.showSuccessMessage('Leave requested successfully')
            this.$emit('toggleSideBars')
            this.resetForm()
          }else{
            if(response.data.conflict){
              this.showLeaveConflictErrorMessage(response)
            }
          }
        } catch (error) {
          this.convertAndNotifyError(error)
        }
     }
     else{
      this.isEndDate = true;
     }
      }
    },
    annualLeaveHours(){
      try{
        let totalCalc = 0;
        for(let leave of this.leaveItems){
          if(leave.startTime && leave.endTime){
          let start = (leave.startTime).split(':')
            let end = (leave.endTime).split(':')
            let break_minutes = leave.break_minutes
            let startDate = new Date()
            let endDate = new Date();

            startDate.setHours(start[0], start[1])
            endDate.setHours(end[0], end[1])

            let timeDiffHours =  new DateDiff(new Date(endDate), new Date(startDate)).hours()
            break_minutes > 0 ? timeDiffHours = (((timeDiffHours * 60) - (break_minutes * 1)) / 60) : ''
            totalCalc = totalCalc + timeDiffHours;
          }
          if(this.trackLeaveOnDays){
            if((this.totalRequestedTime / this.practiceWorkingHours) > 0){
              this.classBind = false
            }else{
              this.classBind = true
            }

          }else{
            if(((this.allocatedLeaveHours - this.usedLeaveHours) - this.totalRequestedTime) > 0){
              this.classBind = false
            }else{
              this.classBind = true
            }
          }
        }
        this.totalRequestedTime = totalCalc
      }catch(error){
        console.log(error)
      }
    },

    hoursCalculate(workingHours){
      try{
        if(workingHours.startTime && workingHours.endTime){
          let start = (workingHours.startTime).split(':')
          let end = (workingHours.endTime).split(':')
          let break_minutes = workingHours.break_minutes
          let startDate = new Date()
          let endDate = new Date();

          startDate.setHours(start[0], start[1])
          endDate.setHours(end[0], end[1])

          let timeDiffHours =  new DateDiff(new Date(endDate), new Date(startDate)).hours()
          break_minutes > 0 ? timeDiffHours = (((timeDiffHours * 60) - (break_minutes * 1)) / 60) : ''
          this.annualLeaveHours()
          return timeDiffHours.toFixed(1);
        }
      }catch(error){
        console.log(error)
      }
    },
    async getUserSummaryDetails(id) {
      try {
        const Response = await AdminLeaveAPI.userSummary(id);
        let leave_takable_count = Response.data.data.users[0].leave_takable_count;
        let working_hours_per_day = Response.data.data.working_hours_per_day;
        let user_total_leave_per_year = Response.data.data.user_total_leave_per_year;

        let total_work_hours = user_total_leave_per_year * working_hours_per_day;
        let remaing = total_work_hours - leave_takable_count;
        let used_days = remaing / working_hours_per_day;
        this.usedLeaveDays = used_days;
        this.allocatedLeaveDays = Response.data.data.users[0].number_of_leaves_per_year;
        this.trackLeaveOnDays = !!Response.data.data.practice.track_leave_on_days;
        this.allocatedLeaveHours = user_total_leave_per_year * working_hours_per_day;
        this.usedLeaveHours = used_days * working_hours_per_day;
      }catch(error){
        this.convertAndNotifyError(error)
      }
    },
    resetForm () {
      this.leaveItems = [{
        id: 1,
        date: '',
        startTime: '',
        endTime: ''
      }],
      this.StaffMember = '',
      this.admin_leave = '',
      this.startDate = '',
      this.endDate = '',
      this.Reason = '',
      this.leaveReason=''
      this.$refs.refFormObserver.reset();
    },
    async getStaff () {
      try {
        const Response = await AdminLeaveAPI.staffList(this.filterQuery,'salaried-staff')
        this.options = Response.data.data.map((x) => ({
          id: x.user.id,
          name: x.user.first_name + ' ' + x.user.last_name,
        }))
        let allMember = [
          {
            id: 0,
            name: 'All Staff'
          }
        ]
        this.options = [...this.options, ...allMember]
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    addLeaveMySelft(){
      this.StaffMember = []
      this.StaffMember[0] = this.currentUserId
    },
    async getLeaveReasons(){
      try{
        const leaves = await AdminLeaveAPI.getLeaveReasons()
        this.leaveReasonOptions = leaves.data.data
      }catch(error){
        this.convertAndNotifyError(error)
      }
    },
    async getSicknessTypes(data){
      try{
        if(data === 1){
          this.formLoading = true
          const leaves = await AdminLeaveAPI.getSicknessTypes()
          this.sicknessTypeOptions = leaves.data.data
          this.showSicknesInput = true
          this.formLoading = false
        }else{
          this.formLoading = true
          this.sicknessTypeOptions = null
          this.sicknessType = ''
          this.showSicknesInput = false
          this.formLoading = false
        }
      }catch(error){
        this.formLoading = false
        this.convertAndNotifyError(error)
      }
    },

    async getPracticeDetails() {
      try {
        const Response = await PracticeAPI.getPracticeDetails();
        this.practiceWorkingHours = Response.data.data.working_hours_per_day;
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },


    async getManageLeaveDetails() {
        const Response = await AdminLeaveAPI.manageLeaveDetails();
        this.isIncludeBankHolidays = !!Response.data.data.practice_details[0].include_bank_holidays
    },

  },
  mounted () {
    // this.getStaff()
    // this.getLeaveReasons()
    // this.practiceWorkingHours = this.$store.state.userWorkingHoursPerDay
    this.trackLeaveOnDays
    this.getPracticeDetails();
    this.getManageLeaveDetails();
    this.userDetails = this.$store.state.userDetails
    this.currentUserId = this.userDetails.id
  },

}
</script>

<style lang="scss" scoped>

#connection_table td:nth-child(2) {
  padding-left: 15px !important;
}

.minus-value{
  color:#cc0000
}

.plus-value{
  color:#1B9AAA
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
